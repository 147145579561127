import { render, staticRenderFns } from "./CatalogTreeStructureForm.vue?vue&type=template&id=a48fcd48&scoped=true"
import script from "./CatalogTreeStructureForm.vue?vue&type=script&lang=js"
export * from "./CatalogTreeStructureForm.vue?vue&type=script&lang=js"
import style0 from "./CatalogTreeStructureForm.vue?vue&type=style&index=0&id=a48fcd48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a48fcd48",
  null
  
)

export default component.exports
<template>
	<v-layout column>
		<v-flex shrink>
			<v-select
				v-model="catalogTreeStructures"
				disabled
				item-text="name"
				item-value="id"
				:items="catalogTreeStructures"
				:label="$t('workspaces.labels.catalog_tree_structures') + '*'"
				:loading="loading"
				multiple
				return-object
			/>
		</v-flex>
		<v-flex shrink>
			<v-select
				v-model="defaultCatalogTreeStructure"
				disabled
				item-text="name"
				item-value="id"
				:items="catalogTreeStructures"
				:label="$t('workspaces.labels.default_catalog_tree_structure') + '*'"
				:loading="loading"
				return-object
			/>
		</v-flex>
		<v-flex shrink>
			<v-select
				v-model="accountingFirmCatalogTreeStructure"
				disabled
				item-text="name"
				item-value="id"
				:items="accountingFirmCatalogTreeStructure ? [accountingFirmCatalogTreeStructure] : []"
				:label="$t('workspaces.labels.accounting_firm_catalog_tree_structure') + '*'"
				:loading="loading"
				return-object
			/>
		</v-flex>
	</v-layout>
</template>

<script>
import WorkspaceModuleGuard from '@/mixins/ModulesGuards/Workspaces/WorkspaceModuleGuard'

export default {
	name: 'CatalogTreeStructureForm',
	mixins: [WorkspaceModuleGuard],
	inject: ['holdingContext'],
	props: {
		value: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			accountingFirmCatalogTreeStructure: null,
			defaultCatalogTreeStructure: null,
			catalogTreeStructures: [],
			loading: false
		}
	},
	computed: {
		accountingFirmId: function () {
			return this.value
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (value) {
				if (value) {
					this.loadCatalogTreeStructures()
				}
			}
		}
	},
	mounted: function () {
		if (this.accountingFirmId) {
			this.loadCatalogTreeStructures()
		}
	},
	methods: {
		findAccountingFirmCatalogTreeStructure: function () {
			return this.service
				.findAccountingFirmCatalogTreeStructure(this.holdingId, this.accountingFirmId)
				.then(catalogTreeStructure => {
					this.accountingFirmCatalogTreeStructure = catalogTreeStructure
				})
				.catch(() => {
					this.accountingFirmCatalogTreeStructure = null
				})
		},
		findDefaultCatalogTreeStructure: function () {
			return this.service
				.listCatalogTreeStructures(this.holdingId, this.accountingFirmId, {
					is_default: true
				})
				.then(catalogTreeStructure => {
					this.defaultCatalogTreeStructure = catalogTreeStructure
				})
				.catch(() => {
					this.defaultCatalogTreeStructure = null
				})
		},
		listCatalogTreeStructures: function () {
			return this.service
				.listCatalogTreeStructures(this.holdingId, this.accountingFirmId)
				.then(catalogTreeStructures => {
					this.catalogTreeStructures = catalogTreeStructures
				})
				.catch(() => {
					this.catalogTreeStructures.splice(0, this.catalogTreeStructures.length)
				})
		},
		loadCatalogTreeStructures: function () {
			this.loading = true

			Promise.all([this.findAccountingFirmCatalogTreeStructure(), this.findDefaultCatalogTreeStructure(), this.listCatalogTreeStructures()]).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>
:deep(.v-input__slot) {
	cursor: default !important;
}
</style>